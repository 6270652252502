<template>
  <div>
    <el-table
      ref="tableListRef"
      v-table-skeleton="{ loading: props.tableLoading, rows: 17 }"
      :data="props.tableData.list"
      border
      stripe
      :height="props.tableHeight"
      class="elv-valuation-record-table"
      header-cell-class-name="elv-valuation-record-table-header__cell"
      header-row-class-name="elv-valuation-record-table-header"
      row-class-name="elv-valuation-record-table-row"
      cell-class-name="elv-valuation-record-table-row__cell"
    >
      <el-table-column type="index" width="40" align="center" class-name="elv-valuation-record-table-row__cell-index">
        <template #default="{ row }">
          <span>{{ (row.rowIndex + 1) * props.params.page }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.adjustingId')" width="128">
        <template #default="{ row }">
          <TextCell :text="row.adjustingNo" :weight="500" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.type')" width="100">
        <template #default="{ row }">
          <TextCell :text="getTypeName(row.type) ?? ''" font-family="Plus Jakarta Sans" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.date')" width="125">
        <template #default="{ row }">
          <TextCell :text="formatDatetime(row.date)" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.currency')" width="150">
        <template #default="{ row }">
          <IconWithTextCell
            :icon="row.underlyingCurrency?.logo"
            :title="row.underlyingCurrency?.showSymbol"
            title-font-family="Barlow"
            :title-weight="400"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('valuation.crypto.chartOfAccount')"
        width="125"
        show-overflow-tooltip
        class-name="elv-valuation-record-table-row__cell-referenceNo"
      >
        <template #default="{ row }">
          <TextCell :text="row.chartOfAccount?.name" />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.auxiliaryCode')" width="110">
        <template #default="{ row }">
          <TextCell :text="getAuxiliaryCodes(row)" font-family="Plus Jakarta Sans" />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('valuation.crypto.quantity')"
        width="110"
        class-name="elv-valuation-record-table-row__cell-referenceNo"
      >
        <template #default="{ row }">
          <TextCell
            :text="formatNumber(row.quantity, 20)"
            :titleDesc="formatNumber(row.quantity, 20)"
            :weight="500"
            align="right"
          />
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.exchangeRateFluctuations')" width="180">
        <template #default="{ row }">
          <TextWithIconCell
            :text="formatNumberToSignificantDigits(row.exchangeRateFluctuations, 2, '', false)"
            :titleDesc="formatNumber(row?.exchangeRateFluctuations, 20)"
            :icon="getProviderIcon(row.priceList?.[0]?.priceProvider?.type)"
            :weight="500"
            :hover-show-pop="true"
          >
            <PriceRangePopover v-bind="row" />
          </TextWithIconCell>
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.fiatExchangeGainLoss')" width="160">
        <template #default="{ row }">
          <TextCell
            :text="formatNumberToSignificantDigits(row.gainLoss, 2, '', false)"
            :titleDesc="formatNumber(row.gainLoss, 20)"
            align="right"
            :weight="500"
          />
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.journalNo')" width="130" fixed="right">
        <template #default="{ row }">
          <TextCell :text="row.journalActivity.journalNo" align="right" font-family="Plus Jakarta Sans" />
        </template>
      </el-table-column>
    </el-table>
    <elv-pagination
      class="elv-valuation-record-list-pagination"
      size-popper-class="elv-valuation-record-pagination__sizes-popper"
      :limit="props.params.limit"
      :current-page="props.params.page"
      :total="props.tableData.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { map } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import coinGeckoIcon from '@/assets/img/project/coinGeckoIcon.png'
import defaultPriceIcon from '@/assets/icons/defaultPriceIcon.svg'
import exchangeRateIcon from '@/assets/img/project/exchangeRateIcon.png'

import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import PriceRangePopover from '../../../components/PriceRangePopover.vue'
import { formatNumberToSignificantDigits, formatNumber } from '@/lib/utils'
import TextWithIconCell from '@/components/Base/Table/Cell/TextWithIconCell.vue'
import IconWithTextCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  params: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const emit = defineEmits(['onChangePage', 'onChangePageSize'])

const tableListRef = useTemplateRef('tableListRef')

const getTypeName = (type: string) => {
  const typeMap: any = {
    ASSET: 'Asset',
    LIABILITY: 'Liability',
    EQUITY: 'Equity',
    REVENUE: 'Revenue',
    EXPENSE: 'Expense',
    OTHER: 'Other'
  }
  return typeMap[type]
}

const getProviderIcon = (type: string) => {
  switch (type) {
    case 'COINGECKO':
    case 'API_COINGECKO':
      return coinGeckoIcon
    case 'EXCHANGERATE':
    case 'API_EXCHANGE_RATE':
      return exchangeRateIcon
    default:
      return defaultPriceIcon
  }
}

const getAuxiliaryCodes = (row: any) => {
  return (
    map(row.auxiliaryValueList, (item: any) => {
      return item?.entityAccount?.name || item?.counterparty?.name || item?.auxiliaryItem?.value
    }) || []
  ).join('/')
}

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const formatDatetime = (datetime: string) => {
  return dayjs(datetime).format('YYYY/MM/DD')
}

defineExpose({
  ref: tableListRef
})
</script>

<style lang="scss"></style>
